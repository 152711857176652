import { PACKAGES } from '../../Constants';
import Button from '../../components/Button';
import Container from '../../components/Container';
import ReactTooltip from 'react-tooltip';

const CTAPackagesSection = ({ onPackageSelect, onPageChange }) => {
  return (
    <div className='py-28 border-t'>
      <Container narrow>
        <div className='flex flex-col md:flex-row gap-y-4 md:justify-between'>
          <div
            data-aos='fade-in'
            data-aos-delay='500'
            data-aos-anchor-placement='bottom-bottom'
          >
            <h1 className='text-xl md:text-2xl text-gray-900 tracking-wide md:font-extrabold antialiased'>
              Looking for something more specific?
            </h1>
            <h3 className='text-md md:text-xl text-gray-900 tracking-wide md:font-extrabold antialiased'>
              Send us an email.
            </h3>
          </div>
          <div
            data-aos='fade-in'
            data-aos-delay='550'
            data-aos-anchor-placement='bottom-bottom'
          >
            <div className='flex justify-center text-center gap-2 text-xl'>
              <Button
                variant='link-primary'
                onClick={() => {
                  onPackageSelect(PACKAGES.Package_custom);
                  onPageChange('/contact');
                }}
              >
                Customize Package
              </Button>
              <p data-tip="Contact us to schedule a free consultation for your custom package solution.">
                <svg
                  stroke='currentColor'
                  fill='currentColor'
                  stroke-width='0'
                  viewBox='0 0 512 512'
                  height='1em'
                  width='1em'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'></path>
                </svg>
              </p>
              <ReactTooltip className='w-full text-white text-lg'/>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default CTAPackagesSection;
