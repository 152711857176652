import { motion } from 'framer-motion';
import Container from '../../components/Container';
import Button from '../../components/Button';

const CTASection = ({ onPageChange }) => {
  // Framer motion zoom animation
  const itemZoom = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 0.8, delay: 0.1, ease: [0, 0.71, 0.2, 1.01] },
    },
  };

  return (
    <div className='relative bg-white py-28 md:py-48'>
      <div className='absolute inset-x-0 bottom-0 h-1/2 bg-slate-100' />
      <Container>
        <motion.div
          className='relative bg-white shadow-xl border border-gray-100 rounded-sm py-16 px-12'
          initial='hidden'
          whileInView='visible'
          viewport={{ once: true, margin: '-100px' }}
          variants={itemZoom}
        >
          <div className='text-center'>
            <h1 className='text-3xl text-primary-600 font-bold capitalize'>
              Ready to upgarde your business?
            </h1>
            <div className='flex justify-center'>
              <p className='text-xl text-gray-700 antialiased mt-2 mb-4 max-w-lg'>
                We offer various package solutions customizable for your unique
                business needs.
              </p>
            </div>
            <Button onClick={() => onPageChange('/packages')}>
              View packages
            </Button>
          </div>
        </motion.div>
      </Container>
    </div>
  );
};

export default CTASection;
