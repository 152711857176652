import { motion } from 'framer-motion';
import {
  ChipIcon,
  DeviceMobileIcon,
  PencilIcon,
  UsersIcon,
  CheckCircleIcon,
  LightningBoltIcon,
} from '@heroicons/react/outline';
import Container from '../../components/Container';
import Button from '../../components/Button';
import MOCKUP_TEMPLATE from '../../assets/illustrations/VideographerMockup.png';

/* Framer motion custom variants (animations) */
const list = {
  visible: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.02,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      when: 'afterChildren',
    },
  },
};
const list2 = {
  visible: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.1,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      when: 'afterChildren',
    },
  },
};

const itemShiftUp = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};
const itemShiftUp2 = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0 },
};

const itemFade = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

/* Services Provided */
const Features = [
  {
    icon: (
      <ChipIcon className='w-20 bg-primary-600 text-white p-4 rounded-full shadow-lg' />
    ),
    name: 'Search Engine Optimization (SEO)',
    description:
      'SEO friendly websites to increase traffic and track user activity.',
    bgColor: 'hover:bg-blue-500',
  },
  {
    icon: (
      <DeviceMobileIcon className='w-20 bg-primary-600 text-white p-4 rounded-full shadow-lg' />
    ),
    name: 'Fully Responsive',
    description:
      'Optimized for all screen sizes, providing a better user experience.',
  },
  {
    icon: (
      <LightningBoltIcon className='w-20 bg-primary-600 text-white p-4 rounded-full shadow-lg' />
    ),
    name: 'Speed Optimized',
    description:
      'Developed with optimized code to create a seamless user experience.',
  },
  {
    icon: (
      <CheckCircleIcon className='w-20 bg-primary-600 text-white p-4 rounded-full shadow-lg' />
    ),
    name: 'Future Proof',
    description:
      'Clean, organized, and responsive code to mitigate bugs and ease future modifications.',
  },
  {
    icon: (
      <PencilIcon className='w-20 bg-primary-600 text-white p-4 rounded-full shadow-lg' />
    ),
    name: 'UI/UX Design',
    description:
      'Design created for your business needs and to captivate your targeted audience.',
  },
  {
    icon: (
      <UsersIcon className='w-20 bg-primary-600 text-white p-4 rounded-full shadow-lg' />
    ),
    name: 'Client Sastisfaction',
    description:
      'Every project is engineered with care. We always strive for 100% client satisfaction.',
  },
];

/* Card display of each service */
const Card = (props) => (
  <div className='text-center'>
    <motion.div className='flex justify-center' variants={itemFade}>
      {props.icon}
    </motion.div>
    <motion.h1
      className='text-lg text-gray-900 font-semibold antialiased mt-2'
      variants={itemFade}
    >
      {props.name}
    </motion.h1>
    <div className='flex justify-center'>
      <motion.p
        className='mt-4 text-lg text-gray-700 antialiased max-w-xs'
        variants={itemFade}
      >
        {props.description}
      </motion.p>
    </div>
  </div>
);

const FeatureSection = () => {
  return (
    <div>
      <div className='bg-white py-28 md:py-48'>
        <Container>
          <div className='flex justify-center'>
            <motion.div
              className='text-center max-w-xl'
              initial='hidden'
              whileInView='visible'
              viewport={{ once: true, margin: '-150px' }}
              variants={list}
            >
              <motion.h1
                className='text-3xl text-gray-900 font-bold antialiased'
                variants={itemShiftUp}
              >
                Our Features
              </motion.h1>
              <motion.p
                className='text-xl text-gray-700 antialiased mt-2'
                variants={itemShiftUp}
              >
                We apply the most effective methods to increase traffic and
                optimize your website.
              </motion.p>
            </motion.div>
          </div>

          <motion.div
            className='grid grid-cols-1 md:grid-cols-3 gap-x-12 gap-y-16 mt-16'
            initial='hidden'
            whileInView='visible'
            viewport={{ once: true, margin: '-100px' }}
            variants={list}
          >
            {Features.map((currFeature) => (
              <div className='pt-4 hover:rounded-sm hover:bg-primary-100 transition ease-out duration-300 hover:-translate-y-3'>
                <Card
                  icon={currFeature.icon}
                  name={currFeature.name}
                  description={currFeature.description}
                  key={currFeature.name}
                  iconDelay={currFeature.iconDelay}
                  nameDelay={currFeature.nameDelay}
                  descriptionDelay={currFeature.descriptionDelay}
                />
              </div>
            ))}
          </motion.div>
        </Container>
      </div>

      <div className='bg-primary-50 py-28 md:py-48'>
        <Container>
          <motion.div
            className='flex flex-col md:flex-row gap-12 items-center'
            initial='hidden'
            whileInView='visible'
            viewport={{ once: true, margin: '-100px' }}
            variants={list2}
          >
            <div className='flex justify-center basis-2/3'>
              <motion.img
                src={MOCKUP_TEMPLATE}
                alt='template example'
                className='w-full'
                variants={itemShiftUp2}
              />
            </div>
            <div className='basis-5/12'>
              <motion.h1
                className='text-3xl text-gray-900 font-bold antialiased'
                variants={itemFade}
              >
                What we do
              </motion.h1>
              <motion.p
                className='text-lg text-gray-700 antialiased my-4'
                variants={itemFade}
              >
                Bring your brand to life. We build websites that captivate,
                educate, and engage your customers. Creating easy-to-read
                designs with an eye for aesthetics, ensuring a positive
                user-friendly experience. Discover how we can help you grow your
                business.
              </motion.p>
              <motion.div variants={itemFade}>
                <Button to='/about'>Learn more</Button>
              </motion.div>
            </div>
          </motion.div>
        </Container>
      </div>
    </div>
  );
};

export default FeatureSection;
