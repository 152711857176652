import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { ArrowNarrowRightIcon } from '@heroicons/react/outline';
import Container from '../../components/Container';

const Services = [
  {
    name: 'Landing Page',
    description:
      ' Responsive landing page perfect for establishing an online presence and promoting business legitimacy. Attract your targeted audience with a website design that emanates your brand and promotes your services.',
    redirect: '/packages',
  },
  {
    name: 'Website Development',
    description:
      'Responsive website combined with online marketing optimization tools and techniques. We build your website to grow your brand image, increase traffic and conversion rates. Elevating your online presence to the next level.',
    redirect: '/packages',
  },
  {
    name: 'Website Design',
    description:
      'First impression matter. We create pixel-perfect responsive website design that captivates your targeted audience. Keep your customers engaged in a pleasant user experience and build enduring customer relationships.',
    redirect: '/packages',
  },
];

/* Framer motion custom variants (animations) */
const list = {
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      when: "afterChildren",
    },
  },
}

const itemShiftUp = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
}

const ServiceSection = () => {
  return (
    <div className='py-28 md:py-48 bg-gradient-to-b from-dark to-neutral-700'>
      <Container>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: '-100px' }}
          variants={list}
        >
          <div>
            <motion.h1
              className='text-3xl text-white font-bold antialiased'
              variants={itemShiftUp}
            >
              Our Services
            </motion.h1>
            <motion.p
              className='text-xl text-gray-200 antialiased mt-2'
              variants={itemShiftUp}
            >
              We deliver exceptional services for our clients.
            </motion.p>
          </div>
          <motion.div
            className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 pt-24 gap-12'
            variants={itemShiftUp}
          >
            {Services.map((currService, index) => (
              <Link
                to={currService.redirect}
                className={
                  (index + 1) % 3 === 0
                    ? 'group bg-zinc-900 py-10 px-10 border-2 border-zinc-900 shadow-xl rounded-md hover:border-primary-600 hover:-translate-y-3 transition ease-in-out duration-300 md:col-span-2 lg:col-span-1'
                    : 'group bg-zinc-900 py-10 px-10 border-2 border-zinc-900 shadow-xl rounded-md hover:border-primary-600 hover:-translate-y-3 transition ease-in-out duration-300'
                }
                key={currService.name}
              >
                <h1 className='text-2xl text-white font-bold '>
                  {currService.name}
                </h1>
                <p className='text-lg text-gray-200 antialiased mt-4'>
                  {currService.description}
                </p>
                <div className='mt-4 flex opacity-0 group-hover:opacity-100 transition-opacity ease-in-out duration-500 text-primary-600'>
                  <p className='font-bold antialiased'>Learn more</p>
                  <ArrowNarrowRightIcon className='ml-2 w-6' />
                </div>
              </Link>
            ))}
          </motion.div>
        </motion.div>
      </Container>
    </div>
  );
};

export default ServiceSection;
