import Container from './Container';

const Footer = ({ navigation, social, onPageChange }) => {
  const emailAddress = 'miguellouis@creativefloww.ca';

  // Used for category heaader text
  const HeaderCategory = (props) => (
    <h1 className='text-xl md:text-2xl text-gray-400 font-bold antialiased pb-1 md:pb-4'>
      {props.name}
    </h1>
  );

  return (
    <footer className='bg-dark py-20 md:py-32 bottom-0'>
      <Container>
        <div className='grid sm:grid-cols-2 gap-8 md:grid-cols-3 md:gap-48 justify-center text-center sm:justify-start sm:text-left'>
          {/* Navigation links */}
          <div>
            <HeaderCategory name='Navigate' />
            <div className='flex gap-8'>
              {navigation.map((currNavigation) => (
                <button
                  className='text-base text-gray-400 antialiased hover:text-gray-200'
                  // to={currNavigation.to}
                  onClick={() => onPageChange(currNavigation.to)}
                  key={currNavigation.name}
                >
                  {currNavigation.name}
                </button>
              ))}
            </div>
          </div>
          {/* Contact Information */}
          <div>
            <HeaderCategory name='Contact us' />
            <a
              className='text-base text-gray-400 antialiased hover:text-gray-200'
              href={emailAddress}
            >
              {emailAddress}
            </a>
          </div>
          {/* Social Icons */}
          <div>
            <HeaderCategory name='Follow us' />
            <div className='flex gap-8 justify-center sm:justify-start'>
              {social.map((currSocial) => (
                <a
                  href={currSocial.href}
                  alt={currSocial.name}
                  target='_blank'
                  rel='noreferrer'
                  key={currSocial.name}
                >
                  {currSocial.icon}
                </a>
              ))}
            </div>
          </div>
        </div>
        {/* ALl rights reserved */}
        <div className='mt-28 text-white'></div>
        <p className='mt-4 text-sm text-gray-400 tracking-wide antialiased uppercase'>
          &copy; 2022 CreativeFloww - All rights reserved.
        </p>
      </Container>
    </footer>
  );
};

export default Footer;
