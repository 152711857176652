import { useState, useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { AnimatePresence } from "framer-motion"
import { ReactComponent as LinkedinLogo } from './assets/socials/linkedin.svg';
import { ReactComponent as GithubLogo } from './assets/socials/github.svg';
import { ReactComponent as FacebookLogo } from './assets/socials/facebook.svg';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Packages from './pages/Packages';
import CaseStudies from './pages/CaseStudies';
import Contact from './pages/Contact';
import ContactSubmitted from './pages/ContactSubmitted';
import NotFound from './pages/NotFound';
import Loading from './pages/Loading';
import './App.css';

/**
 * Navigation links (excludes home & contact)
 *
 */
const navigation = [
  { name: 'Home', to: '/' },
  { name: 'About', to: '/about' },
  { name: 'Packages', to: '/packages' },
  // { name: 'Case Studies', to: '/casestudies' },
];

/**
 * Social icons
 * 
 */
const social = [
  {
    name: 'LinkedIn',
    icon: (
      <LinkedinLogo className='h-6 fill-gray-400 transition duration-300 ease-in-out hover:fill-gray-200 hover:cursor-pointer' />
    ),
    href: '',
  },
  {
    name: 'Facebook',
    icon: (
      <FacebookLogo className='h-6 fill-gray-400 transition duration-300 ease-in-out hover:fill-gray-200 hover:cursor-pointer' />
    ),
    href: '',
  },
  {
    // name: 'Github',
    // icon: (
    //   <GithubLogo className='h-6 fill-gray-400 transition duration-300 ease-in-out hover:fill-gray-200 hover:cursor-pointer' />
    // ),
    // href: '',
  },
];

const App = () => {
  /* === Use States === */
  // Handles requested page by user. Example 'about' for /about
  const [pageRequest, setPageRequest] = useState(undefined)
  // After page request from nav, it is set to true for a small duration
  const [loading, setLoading] = useState(false);
  // Handles navigation bar color
  const [navBgColor, setNavBgColor] = useState('bg-dark')
  // Handles Selected package for contact page
  const [packageRequest, setPackageRequest] = useState(undefined)

  /* === Other Local Variables === */
  let location = useLocation();
  let navigate = useNavigate()

  const handleNavBgColor = (bgColor) => { 
    setNavBgColor(bgColor)
  }

  const onPageChange = (to) => {
    setPageRequest(to)
  }

  const onPackageSelect = (packageReq) => {
    setPackageRequest(packageReq)
  }

  // Trigger on page change and first load
  useEffect(() => {
    if (pageRequest !== undefined) {
      document.body.style.overflow = "hidden"
      setLoading(true)
      const timer = setTimeout(() => {
        if (pageRequest !== undefined) {
          navigate(pageRequest)
        }
      }, 2000)

      return () => clearTimeout(timer)
    }
  }, [pageRequest])

  // Trigger when loading state is changed
  useEffect(() => {
    if (loading) {
      const timer = setTimeout(() => {
        setLoading(false)
        document.body.style.overflow = "auto"
      }, 2000)
      return () => clearTimeout(timer)
    }
  }, [loading])

  return (
    <>
      <AnimatePresence>
        {
          loading ? <Loading key='page-loading' /> : null
        }
      </AnimatePresence>
      <Navbar 
        key='navbar' 
        navigation={navigation} 
        bgColor={navBgColor} 
        onPageChange={onPageChange}
      />
      <Routes location={location} key={location.pathname}>
        <Route 
          path='/' 
          element={<Home social={social} handleNavBgColor={handleNavBgColor} onPageChange={onPageChange} />} 
        />
        <Route 
          path='/about' 
          element={<About handleNavBgColor={handleNavBgColor} />} 
        />
        <Route 
          path='/packages' 
          element={<Packages handleNavBgColor={handleNavBgColor} onPackageSelect={onPackageSelect} onPageChange={onPageChange} />} 
        />
        <Route 
          path='/casestudies' 
          element={<CaseStudies />} 
        />
        <Route 
          path='/contact' 
          element={<Contact handleNavBgColor={handleNavBgColor} packageRequest={packageRequest} setPackageRequest={setPackageRequest} /> } 
        />
        <Route 
          path='/submitted' 
          element={<ContactSubmitted handleNavBgColor={handleNavBgColor} /> } 
        />
        <Route 
          path='*' 
          element={<NotFound handleNavBgColor={handleNavBgColor} onPageChange={onPageChange} />} 
        />
      </Routes>
      <Footer 
        navigation={navigation} 
        social={social} 
        onPageChange={onPageChange} 
      />
    </>
   
  );
};

export default App;
