import { Fragment, useEffect, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import Container from './Container';
import Button from './Button';
import LOGO_WHITE from '../assets/logo_white.png';
import LOGO_BLUE from '../assets/logo_blue.png';

const Navbar = ({ navigation, bgColor, onPageChange }) => {
  const [backgroundColor, setBackgroundColor] = useState(bgColor);

  useEffect(() => {
    switch (bgColor) {
      case 'primary':
        setBackgroundColor('bg-primary-600');
        return;
      case 'white':
        setBackgroundColor('white');
        return;
      case 'split-blue':
        setBackgroundColor('bg-white md:bg-split-blue');
        return;
      case 'default':
      default:
        setBackgroundColor('bg-dark');
        return;
    }
  }, [bgColor]);

  return (
    <header className={backgroundColor}>
      <Container>
        <Popover>
          <div className='flex justify-between items-center py-6'>
            {/* logo (left) */}
            <button
              onClick={() => onPageChange('/')}
              className={'transition ease-in-out duration-500 '}
            >
              <span className='sr-only'>Home logo</span>
              <img
                className='h-12 w-auto sm:h-16 hover:opacity-80'
                src={
                  bgColor === 'default' || bgColor === 'split-blue'
                    ? LOGO_BLUE
                    : LOGO_WHITE
                }
                alt='CreativeFloww Design & Development logo'
              />
            </button>

            {/* desktop - navigation links + contact (right) */}
            <div
              className='hidden md:flex md:items-center md:gap-x-10'
              // data-aos='fade-in'
              // data-aos-delay='500'
            >
              {navigation.map((currentNav) => (
                <button
                  onClick={() => onPageChange(currentNav.to)}
                  className={
                    'border-b-2 transition ease-in-out duration-500' +
                    (bgColor === 'primary'
                      ? ' text-white border-primary-600 hover:text-gray-300 hover:border-white '
                      : bgColor === 'white'
                      ? ' text-primary-600 border-white hover:text-primary-400 hover:border-primary-400'
                      : bgColor === 'split-blue'
                      ? ' text-white border-primary-600 hover:text-gray-300 hover:border-white'
                      : ' text-white border-dark hover:text-gray-300 hover:border-primary-600')
                  }
                  key={currentNav.name}
                >
                  {currentNav.name}
                </button>
              ))}
              {bgColor === 'primary' || bgColor === 'split-blue' ? (
                <Button
                  onClick={() => onPageChange('/contact')}
                  variant='light'
                >
                  Contact
                </Button>
              ) : (
                <Button onClick={() => onPageChange('/contact')}>
                  Contact
                </Button>
              )}
            </div>

            {/* mobile - navigation links + contact */}
            <Popover.Button className='md:hidden'>
              <span className='sr-only'>Open menu</span>
              <MenuIcon
                className={
                  'h-8 sm:h-10 transition duration-300 ease-out' +
                  (bgColor === 'white' || bgColor === 'split-blue'
                    ? ' text-gray-900 hover:text-gray-400'
                    : ' text-white hover:text-gray-400')
                }
                aria-hidden='true'
              />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter='transition ease-out duration-200'
              enterFrom='opacity-0 translate-y-1'
              enterTo='opacity-100 translate-y-0'
              leave='transition ease-in duration-150'
              leaveFrom='opacity-100 translate-y-0'
              leaveTo='opacity-0 translate-y-1'
            >
              <Popover.Panel className='absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden'>
                <div
                  className={
                    'rounded-sm shadow-lg ring-1 ring-black ring-opacity-5 pt-2 px-4' +
                    (bgColor === 'white' || bgColor === 'split-blue'
                      ? ' bg-white'
                      : ' bg-dark')
                  }
                >
                  <div className='flex justify-between'>
                    <Popover.Button
                      onClick={() => onPageChange('/')}
                      className={'transition ease-in-out duration-500 font-bold '}
                    >
                      <span className='sr-only'>Home logo</span>
                      <img
                        className='h-12 w-auto sm:h-16 hover:opacity-80'
                        src={
                          bgColor === 'default' || bgColor === 'split-blue'
                            ? LOGO_BLUE
                            : LOGO_WHITE
                        }
                        alt='CreativeFloww Design & Development logo'
                      />
                    </Popover.Button>
                    <Popover.Button>
                      <span className='sr-only'>Close menu</span>
                      <XIcon
                        className={
                          'h-8 sm:h-10 transition duration-300 ease-out' +
                          (bgColor === 'white' || bgColor === 'split-blue'
                            ? ' text-text-900 hover:text-gray-700'
                            : ' text-white hover:text-gray-400')
                        }
                        aria-hidden='true'
                      />
                    </Popover.Button>
                  </div>
                  <div className='flex flex-col py-4 gap-2 text-center'>
                    {navigation.map((currentNav) => (
                      <Popover.Button
                        onClick={() => onPageChange(currentNav.to)}
                        className={
                          'font-semibold py-2' +
                          (bgColor === 'white' || bgColor === 'split-blue'
                            ? ' text-primary-600 hover:text-primary-500 hover:bg-neutral-100'
                            : ' text-white hover:text-gray-400 hover:bg-neutral-900')
                        }
                        key={currentNav.name}
                      >
                        {currentNav.name}
                      </Popover.Button>
                    ))}
                    <Button onClick={() => onPageChange('/contact')} fullWidth>
                      Contact
                    </Button>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </div>
        </Popover>
      </Container>
    </header>
  );
};

export default Navbar;
