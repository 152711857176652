import { motion } from 'framer-motion';
import { ReactComponent as LinkedinLogo } from '../../assets/socials/linkedin.svg';
import { ReactComponent as GithubLogo } from '../../assets/socials/github.svg';
import { ExternalLinkIcon } from '@heroicons/react/outline';
import Container from '../../components/Container';
import PERSON_MIGUEL from '../../assets/team/miguel.jpg';
import PERSON_JONAH from '../../assets/team/jonah.jpg';
import { ABOUT_QUOTES } from '../../Constants';

const TeamSection = () => {
  /* Framer motion custom variants (animations) */
  const list = {
    visible: {
      opacity: 1,
      transition: {
        when: 'beforeChildren',
        staggerChildren: 0.03,
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        when: 'afterChildren',
      },
    },
  };

  const itemShiftUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  /* Person component */
  const PersonCard = (props) => (
    <motion.div
      className='py-28'
      initial='hidden'
      whileInView='visible'
      variants={list}
      transition={{ delay: 0.1 }}
      viewport={{ once: true, margin: '-150px' }}
    >
      <motion.img
        src={props.src}
        className='mx-auto w-72 rounded-full shadow-lg'
        alt={props.alt}
        variants={itemShiftUp}
      />
      <div className='pt-2 text-center'>
        <motion.p
          className='text-lg 3xl:text-xl text-gray-900 antialiased'
          variants={itemShiftUp}
        >
          {props.name}
        </motion.p>
        <motion.p
          className='text-lg 3xl:text-xl text-primary-600 antialiased'
          variants={itemShiftUp}
        >
          {props.position}
        </motion.p>
        <motion.div
          className='flex justify-center items-center gap-4'
          variants={itemShiftUp}
        >
          <a
            href={props.linkedInHref}
            alt='LinkedIn link'
            target='_blank'
            rel='noreferrer'
          >
            <LinkedinLogo className='mx-auto h-5 3xl:h-6 fill-gray-400 transition duration-300 ease-in-out hover:fill-primary-600 hover:cursor-pointer mt-2' />
          </a>
          <a
            href={props.gitHubHref}
            alt='Github link'
            target='_blank'
            rel='noreferrer'
          >
            <GithubLogo className='mx-auto h-6 3xl:h-6 fill-gray-400 transition duration-300 ease-in-out hover:fill-primary-600 hover:cursor-pointer mt-2' />
          </a>
          <a
            href={props.portfolioHref}
            alt='Portfolio link'
            target='_blank'
            rel='noreferrer'
          >
            <ExternalLinkIcon className='mx-auto h-6 3xl:h-6 text-gray-400 transition duration-300 ease-in-out hover:text-primary-600 hover:cursor-pointer mt-2' />
          </a>
        </motion.div>
      </div>
    </motion.div>
  );

  /* Quote component */
  const Quote = (props) => (
    <motion.blockquote
      className={
        'text-lg 3xl:text-xl antialiased italic relative z-10' +
        (props.textColor === 'white' ? ' text-white' : ' text-gray-900')
      }
      initial='hidden'
      whileInView='visible'
      variants={itemShiftUp}
      transition={{ delay: 0.5 }}
      viewport={{ once: true }}
    >
      <div
        className={
          'mr-2 font-dank-mono absolute top-3 -left-8 leading-9 text-9xl z-20' +
          (props.textColor === 'white' ? ' text-blue-500' : ' text-neutral-300')
        }
      >
        &ldquo;
      </div>
      <p className='z-50 relative'>{props.text}</p>
    </motion.blockquote>
  );

  return (
    <div>
      <div className='md:bg-split-blue'>
        <Container>
          <div className='flex flex-col md:flex-row md:justify-between md:items-center'>
            <div>
              <motion.h1
                className='text-5xl text-gray-900 font-bold antialiased pt-8 pb-8'
                initial='hidden'
                whileInView='visible'
                variants={itemShiftUp}
                viewport={{ once: true }}
              >
                About Us
              </motion.h1>
              <PersonCard
                src={PERSON_MIGUEL}
                name='Miguel Louis'
                position='Founder & Front-End Developer'
                alt='Picture of Miguel, the founder & a full-stack developer.'
                linkedInHref='https://www.linkedin.com/in/miguel-louis-139949238/'
                // portfolioHref='https://www.miguellouis.ca/'
                gitHubHref='https://github.com/SudoMig'
              />
            </div>
            <div className='hidden md:block py-48'>
              <div className='flex justify-center'>
                <div className='md:max-w-sm lg:max-w-md xl:max-w-lg'>
                  <Quote text={ABOUT_QUOTES.Miguel} textColor='white' />
                </div>
              </div>
            </div>
          </div>
        </Container>
        {/* Mobile - Unfortunately, it is duplicate code but it works. This one done for the split bg color */}
        <div className='block md:hidden w-full py-28 bg-primary-600'>
          <div className='flex justify-center'>
            <div className='max-w-xs sm:max-w-sm'>
              <Quote text={ABOUT_QUOTES.Miguel} textColor='white' />
            </div>
          </div>
        </div>
      </div>

      <div className='pt-8'>
        <Container>
          <div className='flex flex-col md:flex-row md:justify-between md:items-center'>
            <div className='pt-28 pb-20 md:py-48 order-2 md:order-1 border-t md:border-none'>
              <div className='flex justify-center'>
                <div className='max-w-xs sm:max-w-sm lg:max-w-md xl:max-w-lg'>
                  <Quote text={ABOUT_QUOTES.Jonah} textColor='black' />
                </div>
              </div>
            </div>
            <div className='order-1 md:order-2'>
              <PersonCard
                src={PERSON_JONAH}
                name='Jonah Louis'
                position='Full-Stack Developer Volunteer'
                alt='Picture of Jonah, a full stack developer volunteer.'
                linkedInHref='https://www.linkedin.com/in/jonah-louis/'
                portfolioHref='https://jonahlouis.ca/'
                gitHubHref='https://github.com/jonahlouis4'
              />
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default TeamSection;
