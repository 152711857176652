import { useEffect, useState } from 'react';
import { motion, usePresence } from 'framer-motion';
import { QUOTES } from '../Constants';
import '../App.css';

const Loading = () => {
  const [isPresent, safeToRemove] = usePresence();
  const [quote, setQuote] = useState(undefined);

  // This makes sure that "Loading" component is removed from the DOM tree after its animation
  useEffect(() => {
    !isPresent && setTimeout(safeToRemove, 100);
  }, [isPresent]);

  // Pick a random quote on every load
  useEffect(() => {
    const quoteLength = QUOTES.length;
    const quoteIndex = Math.floor(Math.random() * (quoteLength - 0) + 0);

    if (quote === undefined) {
      setQuote(QUOTES[quoteIndex]);
    }
  });

  return (
    <motion.div
      initial={{ y: -1500 }}
      animate={{ y: 0 }}
      exit={{ y: 1500 }}
      transition={{ y: { duration: 0.5 } }}
      className='z-40 fixed flex justify-center items-center h-screen w-full bg-dark'
    >
      <div className='dots-flow'></div>

      {/* <div className='text-center'>
        <div className='max-w-lg md:max-w-2xl'>
          <h1 className='text-white text-3xl md:text-5xl font-bold'>{quote}</h1>
          <p className='text-primary-500 font-bold mt-4 flex items-center justify-center w-full'>
            <span className='text-xl'>CreativeFloww</span>
            <span className='text-sm'>&copy;</span>
          </p>
        </div>
      </div> */}
    </motion.div>
  );
};

export default Loading;
