import Container from '../../components/Container';
import Button from '../../components/Button';
import MOCKUP_IMAGE from '../../assets/illustrations/SECMockup.png';
import { motion } from 'framer-motion';

const HeroSection = ({ social, onPageChange }) => {
  /* Framer motion custom variants (animations) */
  const list = {
    visible: {
      opacity: 1,
      transition: {
        when: 'beforeChildren',
        staggerChildren: 0.05,
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        when: 'afterChildren',
      },
    },
  };

  const item = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  const itemFadeUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div className='relative py-28 lg:py-44 bg-gradient-to-b from-dark to-neutral-700'>
      <Container>
        <motion.div
          className='flex flex-col gap-y-24 lg:flex-row lg:gap-y-0 justify-between items-center '
          initial='hidden'
          whileInView='visible'
          viewport={{ once: true }}
          variants={list}
        >
          <div className='text-center lg:text-left sm:max-w-2xl'>
            <motion.h1
              className='text-6xl font-extrabold'
              variants={itemFadeUp}
            >
              <span className='block text-white'>We create websites</span>
              <span className='block text-primary-600'>
                that help your business grow.
              </span>
            </motion.h1>
            <motion.div
              className='flex justify-center lg:justify-start gap-x-6 pt-6'
              variants={itemFadeUp}
            >
              <Button onClick={() => onPageChange('/packages')}>
                View Packages
              </Button>

              <Button
                onClick={() => onPageChange('/contact')}
                variant='secondary'
              >
                Hire us
              </Button>
            </motion.div>
          </div>

          <div className='flex justify-center items-center'>
            <motion.img
              src={MOCKUP_IMAGE}
              alt='project template example'
              variants={itemFadeUp}
            />
          </div>
        </motion.div>
      </Container>
      <div className='absolute right-0 bottom-0 px-4 py-4 md:px-16 md:py-8'>
        <div className='border-t-2 border-primary-600'>
          <motion.div
            className='flex gap-4 pt-2'
            initial='hidden'
            whileInView='visible'
            viewport={{ once: true }}
            variants={list}
          >
            {social.map((currSocial) => (
              <motion.a
                href={currSocial.href}
                alt={currSocial.name}
                target='_blank'
                rel='noreferrer'
                key={currSocial.name}
                variants={item}
              >
                {currSocial.icon}
              </motion.a>
            ))}
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
