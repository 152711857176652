import { Fragment, useState, useEffect } from 'react';
import {
  CheckCircleIcon,
  ArrowSmDownIcon,
  XIcon,
} from '@heroicons/react/outline';
import { PACKAGES } from '../../Constants';
import Container from '../../components/Container';
import Button from '../../components/Button';
import ReactTooltip from 'react-tooltip';
import '../../App.css'

const FeaturedPackages = ({ onPackageSelect, onPageChange }) => {
  const [showMoreDetails, setShowMoreDetails] = useState(false);
  const PACKAGE_ONE = PACKAGES.Package_one,
    PACKAGE_TWO = PACKAGES.Package_two,
    PACKAGE_THREE = PACKAGES.Package_three;

  const plans = [
    {
      title: PACKAGE_ONE,
      description: '~ 6 Day Delivery',
      featured: false,
      price: 129,
      name: 'Design UI/UX',
      mainFeatures: [
        'Figma UI/UX Design',
        'Pixel Perfect & quality design',
        'UI Guidelines with style guide',
      ],
    },
    {
      title: PACKAGE_TWO,
      description: '~ 12 Day Delivery',
      featured: false,
      price: 589,
      name: 'Website Developement',
      mainFeatures: [
        'Everything in Basic & Standard Package',
        'Social Media Icons',
        'Opt-In Form/ On-Site Contact Form',
        'On-page SEO/ Search Engine Optimization',
        'Website hosting through your domain',
      ],
    },
    {
      title: PACKAGE_THREE,
      description: ' ~ 9 Day Delivery',
      featured: true,
      price: 209,
      name: 'Landing Page',
      mainFeatures: [
        'Figma UI/UX Design',
        'Fully Responsive',
        'Hover effects and transition animations',
      ],
    },
  ];

  const sections = [
    {
      title: 'Design Section',
      features: [
        {
          title: 'Figma UI/UX Design',
          plans: { PACKAGE_ONE: true, PACKAGE_TWO: true, PACKAGE_THREE: true },
        },
        {
          title: 'Pixel Perfect & High-Quality Design (Figma)',
          plans: { PACKAGE_ONE: true, PACKAGE_TWO: true, PACKAGE_THREE: false },
        },
        {
          title: 'Fully Responsive For Mobile & Tablet',
          plans: { PACKAGE_ONE: true, PACKAGE_TWO: true, PACKAGE_THREE: true },
        },
        {
          title: 'UI Guidelines With Style Guide',
          plans: {
            PACKAGE_ONE: true,
            PACKAGE_TWO: true,
            PACKAGE_THREE: false,
          },
        },
        {
          title: 'Social Media Icons',
          plans: {
            PACKAGE_ONE: true,
            PACKAGE_TWO: true,
            PACKAGE_THREE: true,
          },
        },
      ],
    },
    {
      title: 'Web Development',
      features: [
        {
          title: 'Pages',
          plans: {
            PACKAGE_ONE: 'up to 3 Pages',
            PACKAGE_TWO: 'up to 6 Pages',
            PACKAGE_THREE: '1 Page',
          },
        },
        {
          title: 'Contact Form/ Opt-In Form  ',
          plans: {
            PACKAGE_ONE: false,
            PACKAGE_TWO: true,
            PACKAGE_THREE: false,
          },
        },
        {
          title: 'On-Page SEO/ Optimization For Better Google Traffic ',
          plans: {
            PACKAGE_ONE: false,
            PACKAGE_TWO: true,
            PACKAGE_THREE: true,
          },
        },
        {
          title: 'Hover Effects & Transition Animations',
          plans: {
            PACKAGE_ONE: false,
            PACKAGE_TWO: true,
            PACKAGE_THREE: true,
          },
        },
        {
          title: 'Website Upload Via Your Domain',
          plans: {
            PACKAGE_ONE: false,
            PACKAGE_TWO: true,
            PACKAGE_THREE: true,
          },
        },
        {
          title: 'Contact Support',
          plans: {
            PACKAGE_ONE: true,
            PACKAGE_TWO: true,
            PACKAGE_THREE: true,
          },
        },
        {
          title: 'Unlimted Reviews / Until Client Satisfaction',
          plans: {
            PACKAGE_ONE: true,
            PACKAGE_TWO: true,
            PACKAGE_THREE: true,
          },
        },
      ],
    },
  ];

  const [showCustomPackage, setShowCustomPackage] = useState(false);

  useEffect(() => {
    setInterval(() => {
      setShowCustomPackage(true);
    }, 3000);
  }, []);

  return (
    <div className='pb-20'>
      <div className='bg-primary-600'>
        <Container>
          <div className='pt-12 lg:pt-20'>
            <div className='text-center'>
              <h2
                className='leading-6 font-semibold text-gray-300 uppercase antialiased'
                data-aos='fade-in'
                data-aos-delay='500'
                data-aos-anchor-placement='bottom-bottom'
              >
                Pricing
              </h2>
              <p
                className='mt-2 text-5xl font-extrabold text-white antialiased capitalize'
                data-aos='fade-in'
                data-aos-delay='550'
                data-aos-anchor-placement='bottom-bottom'
              >
                Pick a package solutions or create your own
              </p>
              <p
                className='mt-2 max-w-3xl mx-auto text-xl text-gray-200 '
                data-aos='fade-in'
                data-aos-delay='600'
                data-aos-anchor-placement='bottom-bottom'
              >
                We provide the website solution for your business
              </p>
            </div>
          </div>
        </Container>

        <div className='mt-16 pb-12 bg-white'>
          <div className='relative z-0'>
            <div className='absolute inset-0 h-5/6 bg-primary-600 lg:h-2/3' />
            <Container>
              <div className='relative lg:grid lg:grid-cols-7'>
                {/* Package #1 */}
                <div
                  className='mx-auto max-w-md lg:mx-0 lg:max-w-none lg:col-start-1 lg:col-end-3 lg:row-start-2 lg:row-end-3'
                  data-aos='fade-in'
                  data-aos-delay='500'
                  data-aos-anchor-placement='top-center'
                >
                  <div className='h-full flex flex-col rounded-md shadow-lg overflow-hidden lg:rounded-none lg:rounded-l-md border border-gray-200'>
                    <div className='flex-1 flex flex-col'>
                      <div className='bg-white px-6 py-10 text-center'>
                        <div>
                          <h3
                            className='text-center text-2xl font-medium text-gray-900 antialiased border-b-2 border-primary-600 inline'
                            id='tier-hobby'
                          >
                            {plans[0].title}
                          </h3>
                          <div className='pt-2'>
                            <span className=' font-semibold text-2xl'>
                              {plans[0].name}
                            </span>
                          </div>
                          <div className='mt-4 flex items-center justify-center'>
                            <span className='px-3 flex items-start text-6xl tracking-tight text-gray-900 antialiased'>
                              <span className='mt-2 mr-2 text-4xl font-medium'>
                                $
                              </span>
                              <span className='font-extrabold'>
                                {plans[0].price}
                              </span>
                            </span>
                            <span className='text-sm font-medium text-gray-500 antialiased'>
                              /one time fee
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className='flex-1 flex flex-col justify-between border-t-2 border-gray-100 p-6 bg-gray-50 sm:p-10 lg:p-6 xl:p-10'>
                        <ul className='space-y-4'>
                          {plans[0].mainFeatures.map((feature) => (
                            <li key={feature} className='flex items-start'>
                              <div className='flex-shrink-0'>
                                <CheckCircleIcon
                                  className='flex-shrink-0 h-6 w-6 text-primary-500'
                                  aria-hidden='true'
                                />
                              </div>
                              <p className='ml-3 text-base font-medium text-gray-500 antialiased'>
                                {feature}
                              </p>
                            </li>
                          ))}
                        </ul>
                        <div className='mt-8'>
                          <Button
                            onClick={() => {
                              onPackageSelect(plans[0].title);
                              onPageChange('/contact');
                            }}
                            variant='secondary'
                            fullWidth
                          >
                            Get started
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Package #2 */}
                <div
                  className='mt-10 max-w-lg mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-start-3 lg:col-end-6 lg:row-start-1 lg:row-end-4'
                  data-aos='fade-in'
                  data-aos-delay='550'
                  data-aos-anchor-placement='top-center'
                >
                  <div className='relative z-10 rounded-md shadow-xl'>
                    <div
                      className='pointer-events-none absolute inset-0 rounded-md border-2 border-primary-400'
                      aria-hidden='true'
                    />
                    <div className='absolute inset-x-0 top-0 transform translate-y-px'>
                      <div className='flex justify-center transform -translate-y-1/2'>
                        <span className='inline-flex rounded-md bg-green-400 px-4 py-1 text-sm font-semibold tracking-wider uppercase text-white antialiased'>
                          Recommended
                        </span>
                      </div>
                    </div>
                    <div className='bg-white rounded-t-lg px-6 pt-12 pb-10 text-center'>
                      <div>
                        <h3
                          className='text-center text-2xl font-semibold text-gray-900 sm:-mx-6 border-b-2 border-primary-600 inline'
                          id='tier-growth'
                        >
                          {plans[1].title}
                        </h3>
                        <div className='pt-2'>
                          <span className='font-semibold text-3xl text-blue-600'>
                            {plans[1].name}
                          </span>
                        </div>
                        <div className='mt-4 flex items-center justify-center'>
                          <span className='px-3 flex items-start text-6xl tracking-tight antialiased text-gray-900 sm:text-6xl'>
                            <span className='mt-2 mr-2 text-4xl font-medium'>
                              $
                            </span>
                            <span className='font-extrabold'>
                              {plans[1].price}
                            </span>
                          </span>
                          <span className='text-sm font-medium text-gray-500'>
                            /one time fee
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className='border-t-2 border-gray-100 rounded-b-lg pt-10 pb-8 px-6 bg-gray-50 sm:px-10 sm:py-10'>
                      <ul className='space-y-4'>
                        {plans[1].mainFeatures.map((feature) => (
                          <li key={feature} className='flex items-start'>
                            <div className='flex-shrink-0'>
                              <CheckCircleIcon
                                className='flex-shrink-0 h-6 w-6 text-primary-500'
                                aria-hidden='true'
                              />
                            </div>
                            <p className='ml-3 text-base font-medium text-gray-500 antialiased'>
                              {feature}
                            </p>
                          </li>
                        ))}
                      </ul>
                      <div className='mt-10'>
                        <Button
                          onClick={() => {
                            onPackageSelect(plans[1].title);
                            onPageChange('/contact');
                          }}
                          fullWidth
                        >
                          Get started
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Package #3 */}
                <div
                  className='mt-10 mx-auto max-w-md lg:m-0 lg:max-w-none lg:col-start-6 lg:col-end-8 lg:row-start-2 lg:row-end-3'
                  data-aos='fade-in'
                  data-aos-delay='600'
                  data-aos-anchor-placement='top-center'
                >
                  <div className='h-full flex flex-col rounded-md shadow-lg overflow-hidden lg:rounded-none lg:rounded-r-md border border-gray-200'>
                    <div className='flex-1 flex flex-col'>
                      <div className='bg-white px-6 py-10 text-center'>
                        <div>
                          <h3
                            className='text-center text-2xl font-medium text-gray-900 border-b-2 border-primary-600 inline'
                            id='tier-scale'
                          >
                            {plans[2].title}
                          </h3>
                          <div className='pt-2'>
                            <span className='font-semibold text-2xl'>
                              {plans[2].name}
                            </span>
                          </div>
                          <div className='mt-4 flex items-center justify-center'>
                            <span className='px-3 flex items-start text-6xl tracking-tight text-gray-900 antialiased'>
                              <span className='mt-2 mr-2 text-4xl font-medium'>
                                $
                              </span>
                              <span className='font-extrabold'>
                                {plans[2].price}
                              </span>
                            </span>
                            <span className='text-sm font-medium text-gray-500'>
                              /one time fee
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className='flex-1 flex flex-col justify-between border-t-2 border-gray-100 p-6 bg-gray-50 sm:p-10 lg:p-6 xl:p-10'>
                        <ul className='space-y-4'>
                          {plans[2].mainFeatures.map((feature) => (
                            <li key={feature} className='flex items-start'>
                              <div className='flex-shrink-0'>
                                <CheckCircleIcon
                                  className='flex-shrink-0 h-6 w-6 text-primary-500'
                                  aria-hidden='true'
                                />
                              </div>
                              <p className='ml-3 text-base font-medium text-gray-500 antialiased'>
                                {feature}
                              </p>
                            </li>
                          ))}
                        </ul>
                        <div className='mt-8'>
                          <Button
                            onClick={() => {
                              onPackageSelect(plans[2].title);
                              onPageChange('/contact');
                            }}
                            variant='secondary'
                            fullWidth
                          >
                            Get started
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Custom Package Popup */}
              <div>
                {showCustomPackage && (
                  <button onClick={() => onPageChange('/contact')} className='md:block hidden fixed bottom-12 right-12 md:py-4 md:px-6 md:h-10 rounded-full drop-shadow-xl bg-primary-600 hover:-translate-y-3 hover:bg-primary-50 hover:border-2 hover:border-primary-600 hover:text-primary-600 text-white transition ease-in-out duration-300 animate cursor-pointer'>
                    <div className='flex justify-center items-center h-full font-medium lg:text-lg'>Custom Package</div>
                  </button>
                )}
              </div>
            </Container>
          </div>
        </div>
      </div>

      {/* More details */}
      <div>
        <div
          className={
            showMoreDetails
              ? 'flex justify-center border-t'
              : 'flex justify-center'
          }
        >
          {!showMoreDetails ? (
            <button
              className='text-lg font-bold text-gray-900 antialiased hover:text-primary-600 pointer'
              data-aos='fade-in'
              data-aos-delay='500'
              onClick={() => setShowMoreDetails(true)}
            >
              <span className='flex items-center '>
                <p className=''>Read more about packages</p>
                <ArrowSmDownIcon className='h-6' />
              </span>
            </button>
          ) : null}
        </div>
        {showMoreDetails ? (
          <Fragment>
            {/* shown for lg+ */}
            <section className='hidden lg:block'>
              <Container>
                <table className='w-full mt-20 text-left'>
                  <thead>
                    <tr>
                      <th className='text-sm font-bold text-gray-900 pb-4 pl-4'>
                        <span className='sr-only'>Feature by</span>
                        <span>Plans</span>
                      </th>
                      {/* Print all package information at the header of the table */}
                      {plans.map((currPlan) => (
                        <th
                          key={currPlan.title}
                          className='leading-6 font-medium pb-4'
                        >
                          <span className='block text-xl text-primary-600 font-bold'>
                            {currPlan.title}
                          </span>
                          <span className='block flex items-center'>
                            <span className='mr-1 text-xl antialiased'>$</span>
                            <span className='text-4xl font-extrabold text-gray-900 tracking-tight antialiased'>
                              {currPlan.price}
                            </span>
                            <span className='mr-1 text-sm text-gray-500 antialiased'>
                              /one time fee
                            </span>
                          </span>
                          <span className='text-sm text-gray-900'>
                            {currPlan.description}
                          </span>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className='mt-4 border-t '>
                    {/* Loop through all sections per package */}
                    {sections.map((currSection) => (
                      <Fragment key={currSection.title}>
                        <tr>
                          <th
                            className='text-sm font-bold text-gray-900 py-4 px-4 border-b bg-gray-50'
                            colSpan={4}
                          >
                            {currSection.title}
                          </th>
                        </tr>
                        {/* Loop through features of the current section */}
                        {currSection.features.map((currFeature) => (
                          <tr key={currFeature.title} className='border-b'>
                            <td className='text-base font-medium text-gray-500 pl-4 py-4'>
                              {currFeature.title}
                            </td>
                            <td>
                              {currFeature.plans.PACKAGE_ONE === true ? (
                                <CheckCircleIcon className='h-6 text-primary-600' />
                              ) : currFeature.plans.PACKAGE_ONE === false ? (
                                <XIcon className='h-6 text-gray-400' />
                              ) : (
                                <p className='font-medium text-primary-600'>
                                  {currFeature.plans.PACKAGE_ONE}
                                </p>
                              )}
                            </td>
                            <td>
                              {currFeature.plans.PACKAGE_TWO === true ? (
                                <CheckCircleIcon className='h-6 text-primary-600' />
                              ) : currFeature.plans.PACKAGE_TWO === false ? (
                                <XIcon className='h-6 text-gray-400' />
                              ) : (
                                <p className='font-medium text-primary-600'>
                                  {currFeature.plans.PACKAGE_TWO}
                                </p>
                              )}
                            </td>
                            <td>
                              {currFeature.plans.PACKAGE_THREE === true ? (
                                <CheckCircleIcon className='h-6 text-primary-600' />
                              ) : currFeature.plans.PACKAGE_THREE === false ? (
                                <XIcon className='h-6 text-gray-400' />
                              ) : (
                                <p className='font-medium text-primary-600'>
                                  {currFeature.plans.PACKAGE_THREE}
                                </p>
                              )}
                            </td>
                          </tr>
                        ))}
                      </Fragment>
                    ))}
                  </tbody>
                </table>
              </Container>
            </section>

            {/* shown for xs to lg */}
            <section className='block lg:hidden'>
              <Container>
                {/* Loop through all plans one by one */}
                {plans.map((currPlan) => (
                  <div className='mt-20'>
                    <h1 className='pb-6'>
                      <span className='block text-xl text-primary-600 font-bold'>
                        {currPlan.title}
                      </span>
                      <span className='block flex items-center'>
                        <span className='mr-1 text-xl antialiased'>$</span>
                        <span className='text-4xl font-extrabold text-gray-900 tracking-tight antialiased'>
                          {currPlan.price}
                        </span>
                        <span className='mr-1 text-sm text-gray-500 antialiased'>
                          /one time fee
                        </span>
                      </span>
                      <span className='text-sm text-gray-900'>
                        {currPlan.description}
                      </span>
                    </h1>

                    <table className='w-full text-left'>
                      <tbody>
                        {/* Loop through all the section for the current plan */}
                        {sections.map((currSection) => (
                          <Fragment key={currSection.title}>
                            <tr>
                              <th
                                className='text-sm font-bold text-gray-900 py-4 px-4 border-y bg-gray-50'
                                colSpan={2}
                              >
                                {currSection.title}
                              </th>
                            </tr>
                            {/* Loop through all the features for the current plan only */}
                            {currSection.features.map((currFeature) => (
                              <tr key={currSection.title} className='border-b'>
                                <td className='text-base font-medium text-gray-500 pl-4 py-4'>
                                  {currFeature.title}
                                </td>
                                {currPlan.title === PACKAGE_ONE ? (
                                  <td className='flex justify-end pr-4 py-4'>
                                    {currFeature.plans.PACKAGE_ONE === true ? (
                                      <CheckCircleIcon className='h-6 text-primary-600' />
                                    ) : currFeature.plans.PACKAGE_ONE ===
                                      false ? (
                                      <XIcon className='h-6 text-gray-400' />
                                    ) : (
                                      <p className='font-medium text-primary-600'>
                                        {currFeature.plans.PACKAGE_ONE}
                                      </p>
                                    )}
                                  </td>
                                ) : null}
                                {currPlan.title === PACKAGE_TWO ? (
                                  <td className='flex justify-end pr-4 py-4'>
                                    {currFeature.plans.PACKAGE_TWO === true ? (
                                      <CheckCircleIcon className='h-6 text-primary-600' />
                                    ) : currFeature.plans.PACKAGE_TWO ===
                                      false ? (
                                      <XIcon className='h-6 text-gray-400' />
                                    ) : (
                                      <p className='font-medium text-primary-600'>
                                        {currFeature.plans.PACKAGE_TWO}
                                      </p>
                                    )}
                                  </td>
                                ) : null}
                                {currPlan.title === PACKAGE_THREE ? (
                                  <td className='flex justify-end pr-4 py-4'>
                                    {currFeature.plans.PACKAGE_THREE ===
                                    true ? (
                                      <CheckCircleIcon className='h-6 text-primary-600' />
                                    ) : currFeature.plans.PACKAGE_THREE ===
                                      false ? (
                                      <XIcon className='h-6 text-gray-400' />
                                    ) : (
                                      <p className='font-medium text-primary-600'>
                                        {currFeature.plans.PACKAGE_THREE}
                                      </p>
                                    )}
                                  </td>
                                ) : null}
                              </tr>
                            ))}
                          </Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ))}
              </Container>
            </section>
          </Fragment>
        ) : null}
      </div>
    </div>
  );
};

export default FeaturedPackages;
