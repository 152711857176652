import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

/**
 * Global button
 * Quick summary of how to call this component:
 *  - If you want a 'Link' to another page, use the 'to' paramter
 *  - If you want to use an button 'onClick' function, use the 'onClick' paramter
 *  - If you want to use an 'a' tag for href, use the 'toId' parameter
 * 
 * @param {string} children  - label of the button
 * @param {function} to - buttton that redirects page
 * @param {function} toId - button that goes to section id
 * @param {function} onClick - function to handle button
 * @param {boolean} fullWidth - set to 'true' if button is full width
 * @param {string} variant - changes button apperance. Options: 'primary', 'secondary', 'light', or 'link-primary'
 * @param {int} tabIndex - set to '-1' to remove access from 'tab' on keyboard press
 * @returns
 */
const Button = ({
  children,
  to = '',
  toId = undefined,
  onClick = undefined,
  fullWidth = false,
  variant = 'primary',
  tabIndex = 0,
}) => {
  const DEFAULT_BTN_STYLE =
    'button-font border-2 py-2 px-6 rounded-sm font-bold transition duration-500 ease-out';
  const DEFAULT_LINK_STYLE =
    'border-gray-300 border-b-2 hover:border-primary-600 text-xl text-gray-900 font-bold transition duration-500 ease-out';
  const [btnStyle, setBtnStyle] = useState('');

  /**
   * Get the width of the button
   *
   */
  const getBtnWidth = () => {
    if (fullWidth) {
      return ' w-full';
    }
  };

  const getBtnVariant = () => {
    switch (variant) {
      case 'link-primary':
        return ' bg-transparent hover:text-primary-600 hover:text-primary-600';
      case 'light':
        return ' bg-white border-white text-primary-600 hover:bg-transparent hover:text-white';
      case 'secondary':
        return ' bg-transparent border-primary-600 text-primary-600 hover:bg-primary-600 hover:text-white';
      case 'primary':
      default:
        return ' bg-primary-600 border-primary-600 text-white hover:bg-transparent hover:text-primary-600 hover:cursor-pointer';
    }
  };

  useEffect(() => {
    const buttonWidth = getBtnWidth();
    const buttonVariant = getBtnVariant();

    variant.includes('link')
      ? setBtnStyle(DEFAULT_LINK_STYLE + buttonWidth + buttonVariant)
      : setBtnStyle(DEFAULT_BTN_STYLE + buttonWidth + buttonVariant);
  }, [getBtnWidth, getBtnVariant]);

  return (
    // <Link> BUTTON TYPE - has 'to' enabled
    to !== '' ? (
      <Link to={to}>
        {variant === 'link-primary' ? (
          <span className={btnStyle} onClick={onClick}>
            {children}
          </span>
        ) : (
          <button className={btnStyle} onClick={onClick} tabIndex={tabIndex}>
            {children}
          </button>
        )}
      </Link>
    // <button> BUTTON TYPE - has 'onClick' and NO 'to'
    ) : onClick !== undefined ? (
      <button className={btnStyle} onClick={onClick} tabIndex={tabIndex}>
        {children}
      </button>
    // <a> BUTTON TYPE - has 'toId' set 
    ) : toId !== undefined ? (
      <a href={'#'+ toId} tabIndex={tabIndex}>
        <button className={btnStyle} onClick={undefined} tabIndex={-1}>
          {children}
        </button>
      </a>
    ) : (
      // <input> BUTTON TYPE - is for a form (Submit)
      <input className={btnStyle} name='Submit' type='Submit' />
    )
  );
};

export default Button;
