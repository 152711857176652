import { useEffect } from 'react';
import EMAIL_SENT_ICON from '../assets/illustrations/email_sent.svg';
import Button from '../components/Button';
import Container from '../components/Container';

const ContactSubmitted = ({ handleNavBgColor }) => {
  useEffect(() => {
    handleNavBgColor('default');
  }, []);

  return (
    <div className='py-28 3xl:py-72'>
      <Container>
        <div className='flex flex-col justify-center text-center'>
          <img src={EMAIL_SENT_ICON} className='h-24 3xl:h-28' alt='Email sent icon' />
          <h1 className='text-4xl 3xl:text-5xl text-gray-900 font-extrabold antialiased mt-8'>
            Thanks for submitting!
          </h1>
          <div className='flex justify-center'>
            <p className='text-xl 3xl:text-2xl text-gray-800 antialiased mt-4 mb-6 max-w-lg 3xl:max-w-xl'>
              Your message has been sent. We will get back to you within 1-3
              business days.
            </p>
          </div>
          <Button to='/'>Return Home</Button>
        </div>
      </Container>
    </div>
  );
};

export default ContactSubmitted;
