import { motion } from 'framer-motion';
import Container from '../../components/Container';
import PROCESS_01_IMG from '../../assets/illustrations/process_01.svg';
import PROCESS_02_IMG from '../../assets/illustrations/process_02.svg';
import PROCESS_03_IMG from '../../assets/illustrations/process_03.svg';

const ProcessSection = () => {
  const list = {
    visible: {
      opacity: 1,
      transition: {
        when: 'beforeChildren',
        staggerChildren: 0.02,
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        when: 'afterChildren',
      },
    },
  };

  const itemShiftUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };
  const itemShiftUpSideRight = {
    hidden: { opacity: 0, y: 50, x: 50 },
    visible: { opacity: 1, y: 0, x: 0 },
  };
  const itemShiftUpSideLeft = {
    hidden: { opacity: 0, y: 50, x: -50 },
    visible: { opacity: 1, y: 0, x: 0 },
  };

  return (
    <div className='pt-28 md:pt-48'>
      <Container>
        <motion.div
          className='text-center'
          initial='hidden'
          whileInView='visible'
          viewport={{ once: true, margin: '-100px' }}
          variants={list}
        >
          <motion.h1
            className='text-3xl text-gray-900 font-bold antialiased capitalize'
            variants={itemShiftUp}
          >
            How does it work?
          </motion.h1>
          <motion.p
            className='text-xl text-gray-700 antialiased mt-2 capitalize'
            variants={itemShiftUp}
          >
            Simple! We follow these three easy steps.
          </motion.p>
        </motion.div>
      </Container>
      <div className='bg-white py-28'>
        <Container>
          <motion.div
            className='flex flex-col gap-y-24 md:gap-y-0 md:flex-row md:justify-between items-center'
            initial='hidden'
            whileInView='visible'
            viewport={{ once: true, margin: '-100px' }}
            variants={list}
          >
            <div>
              <motion.h1
                className='process-header-text text-6xl text-primary-600 font-bold antialiased'
                variants={itemShiftUp}
              >
                01
              </motion.h1>
              <div className='pl-16 max-w-xl'>
                <motion.h2
                  className='text-3xl text-gray-900 font-bold antialiased capitalize'
                  variants={itemShiftUp}
                >
                  Choose a Package Option
                </motion.h2>
                <motion.p
                  className='text-lg text-gray-700 antialiased mt-2'
                  variants={itemShiftUp}
                >
                  Simply select a package option, enter your information in the
                  contact section and submit!
                </motion.p>
              </div>
            </div>
            <motion.div
              className='flex justify-center'
              variants={itemShiftUpSideRight}
            >
              <img
                src={PROCESS_01_IMG}
                className='w-7/12 sm:w-6/12 md:w-full'
                alt='step one process'
              />
            </motion.div>
          </motion.div>
        </Container>
      </div>

      <div className='bg-primary-50 py-28'>
        <Container>
          <motion.div
            className='flex flex-col gap-y-24 md:gap-y-0 md:flex-row md:justify-between items-center'
            initial='hidden'
            whileInView='visible'
            viewport={{ once: true, margin: '-100px' }}
            variants={list}
          >
            <div className='md:order-2'>
              <motion.h1
                className='process-header-text text-6xl text-primary-600 font-bold antialiased'
                variants={itemShiftUp}
              >
                02
              </motion.h1>
              <div className='pl-16 max-w-xl'>
                <motion.h2
                  className='text-3xl text-gray-900 font-bold antialiased capitalize'
                  variants={itemShiftUp}
                >
                  We Design & Build your Website
                </motion.h2>
                <motion.p
                  className='text-lg text-gray-700 antialiased mt-2'
                  variants={itemShiftUp}
                >
                  We'll send you an email with a design questionnaire and a link
                  to schedule an initial meeting. We'll discuss the project details and
                  requirements.  Once the information is gathered, we initiate
                  your websites development. 
                </motion.p>
              </div>
            </div>
            <motion.div
              className='flex justify-center'
              variants={itemShiftUpSideLeft}
            >
              <img
                src={PROCESS_02_IMG}
                className='w-7/12 sm:w-6/12 md:w-full'
                alt='step one process'
              />
            </motion.div>
          </motion.div>
        </Container>
      </div>

      <div className='bg-white pt-28'>
        <Container>
          <motion.div
            className='flex flex-col gap-y-24 md:gap-y-0 md:flex-row md:justify-between items-center'
            initial='hidden'
            whileInView='visible'
            viewport={{ once: true, margin: '-100px' }}
            variants={list}
          >
            <div>
              <motion.h1
                className='process-header-text text-6xl text-primary-600 font-bold antialiased'
                variants={itemShiftUp}
              >
                03
              </motion.h1>
              <div className='pl-16 max-w-xl'>
                <motion.h2
                  className='text-3xl text-gray-900 font-bold antialiased capitalize'
                  variants={itemShiftUp}
                >
                  Website Hand off
                </motion.h2>
                <motion.p
                  className='text-lg text-gray-700 antialiased mt-2'
                  variants={itemShiftUp}
                >
                  Once the project is completed, you'll receive all the project
                  documentation.

                  {/*  This contains the corresponding package
                  details, solution, information, and any additional requests
                  made by the client. */}
                </motion.p>
              </div>
            </div>
            <motion.div
              className='flex justify-center'
              variants={itemShiftUpSideRight}
            >
              <img
                src={PROCESS_03_IMG}
                className='w-7/12 sm:w-6/12 md:w-full'
                alt='step one process'
              />
            </motion.div>
          </motion.div>
        </Container>
      </div>
    </div>
  );
};

export default ProcessSection;
