import { useEffect } from 'react';
import AOS from 'aos';
import FeaturedPackages from '../sections/packages/FeaturedPackages';
import CTAPackagesSection from '../sections/packages/CTAPackagesSection';

const Packages = ({ handleNavBgColor, onPackageSelect, onPageChange }) => {
  useEffect(() => {
    handleNavBgColor('primary');
  }, []);

  useEffect(() => {
    AOS.refresh();
  });

  return (
    <>
      <FeaturedPackages
        onPackageSelect={onPackageSelect}
        onPageChange={onPageChange}
      />
      <CTAPackagesSection
        onPackageSelect={onPackageSelect}
        onPageChange={onPageChange}
      />
    </>
  );
};

export default Packages;
