import { useEffect } from 'react';
import Button from '../components/Button';

const NotFound = ({ handleNavBgColor, onPageChange }) => {
  useEffect(() => {
    handleNavBgColor('default');
  }, []);

  return (
    <div className='h-full flex justify-center items-center py-48 3xl:py-96'>
      <div className='flex flex-col md:flex-row gap-x-8'>
        <h1 className='text-6xl text-primary-600 font-bold'>404</h1>
        <div>
          <div className='md:border-l md:pl-4'>
            <h1 className='text-6xl text-gray-900 antialiased font-bold'>
              Page not found
            </h1>
            <p className='text-lg text-gray-600'>
              Please check the URL in the address bar and try again.
            </p>
          </div>
          <div className='pt-8'>
            <Button onClick={() => onPageChange('/')}>Go back home</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
