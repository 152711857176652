import { motion } from 'framer-motion';
import Container from '../../components/Container';

const MissionSection = () => {
  const list = {
    visible: {
      opacity: 1,
      transition: {
        when: 'beforeChildren',
        staggerChildren: 0.03,
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        when: 'afterChildren',
      },
    },
  };

  const itemShiftUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div className='bg-primary-50 py-28'>
      <Container>
        <motion.div
          initial='hidden'
          whileInView='visible'
          variants={list}
          viewport={{ once: true, margin: '-150px' }}
        >
          <motion.h1
            className='text-3xl text-gray-900 font-bold antialiased pb-8'
            variants={itemShiftUp}
          >
            Our Mission
          </motion.h1>
          <div>
            <motion.p
              className=' text-lg 3xl:text-xl text-gray-900 antialiased'
              variants={itemShiftUp}
            >
              At CreativeFloww, providing exceptional service and obtaining our
              clients' satisfaction are our top priorities. The project isn’t
              completed until you're 100% satisfied. We are committed to
              understanding your project requirements to deliver the
              professional, eye-catching website solution your business needs. A
              well-designed website ensures your targeted audience remains
              engaged and captivated, minimising your bounce rate. We help your
              business maintain a consistent brand image and create a pleasant
              user experience building a lifelong customer relationship. This
              does wonders for establishing a creditable and trustworthy brand
              that fortifies your online presence.
              <br />
              <br />
              We understand that online marketing can be challenging, which is
              why we make it simple by implementing online marketing techniques
              that convert your traffic into sales.
              <br />
              <br />
              By best practice, all our websites are responsive and contain
              clean, commented, and organised code. Making any future
              modification a breeze. We provide professional documentation, such
              as the source code for each of our projects, along with a UI
              guidelines style sheet that contains all of your design
              components. We also offer content management system (CMS) options,
              for our clients that prefer having more customizability over
              their website.
              <br />
              <br />
              Contact us if you have any questions.
            </motion.p>
          </div>
        </motion.div>
      </Container>
    </div>
  );
};

export default MissionSection;
