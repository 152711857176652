import Container from '../../components/Container';
import { motion } from 'framer-motion';
import { ReactComponent as ReactLogo } from '../../assets/clouds/react.svg';
import { ReactComponent as HtmlLogo } from '../../assets/clouds/html5.svg';
import { ReactComponent as CssLogo } from '../../assets/clouds/css3.svg';
import { ReactComponent as JavaScriptLogo } from '../../assets/clouds/javascript.svg';
import { ReactComponent as FigmaLogo } from '../../assets/clouds/figma.svg';
import { ReactComponent as NetlifyLogo } from '../../assets/clouds/netlify.svg';

const logoClassName =
  'h-14 md:h-16 fill-gray-400 group-hover:-translate-y-3 transition ease-out duration-300';

const Logos = [
  {
    logo: (
      <ReactLogo className={logoClassName + ' group-hover:fill-[#00d8ff]'} />
    ),
    href: 'https://reactjs.org/',
  },
  {
    logo: (
      <HtmlLogo className={logoClassName + ' group-hover:fill-[#e34c26]'} />
    ),
    href: 'https://developer.mozilla.org/en-US/docs/Glossary/HTML5',
  },
  {
    logo: (
      <JavaScriptLogo className={logoClassName + ' group-hover:fill-[#F7DF1E]'} />
    ),
    href: 'https://www.javascript.com/',
  },
  {
    logo: <CssLogo className={logoClassName + ' group-hover:fill-[#264de4]'} />,
    href: 'https://developer.mozilla.org/en-US/docs/Web/CSS',
  },
  {
    logo: (
      <FigmaLogo className={logoClassName + ' group-hover:fill-[#ff7262]'} />
    ),
    href: 'https://www.figma.com/',
  },
  // {
  //   logo: (
  //     <NetlifyLogo className={logoClassName + ' group-hover:fill-[#20c6b7]'} />
  //   ),
  //   href: 'https://www.netlify.com/',
  // },
];

/* Framer motion custom variants (animations) */
const list = {
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.03,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      when: "afterChildren",
    },
  },
}

const itemShiftUp = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
}

const itemFade = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
}

const CloudLogoSection = () => {
  return (
    <div className='bg-neutral-700 pb-28 md:pb-48'>
      <Container>
        <motion.div 
          className='flex flex-col justify-center text-center'
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: '-100px' }}
          variants={list}
        >
          <motion.h1
            className='text-lg text-gray-200 font-bold antialiased'
            variants={itemShiftUp}
          >
            Trusted modern technologies used to build your website
          </motion.h1>
          <div className='grid grid-cols-5 pt-14'>
            {Logos.map((currLogo, index) => (
              <motion.a
                href={currLogo.href}
                className='group col-span-1 flex justify-center'
                target='_blank'
                rel='noreferrer'
                key={index}
                variants={itemFade}
              >
                {currLogo.logo}
              </motion.a>
            ))}
          </div>
        </motion.div>
      </Container>
    </div>
  );
};

export default CloudLogoSection;
