import { useEffect } from 'react'
import TeamSection from '../sections/about/TeamSection'
import MissionSection from '../sections/about/MissionSection'

const About = ({handleNavBgColor}) => {
  
  useEffect(() => {
    handleNavBgColor('split-blue')
  })

  return (
    <>
      <TeamSection />
      <MissionSection />
    </>
  )
}

export default About