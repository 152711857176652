// Package
export const PACKAGES = {
  Package_one: 'Basic Package',
  Package_two: 'Premium Package',
  Package_three: 'Standard Package',
  Package_custom: 'Custom Package ',
};

// Quotes for Loading page
export const QUOTES = [
  'Premium websites for a resonable prices.',
  'Every pixel is filled with love ❤️',
  'Obtain the website you envisonned',
  "We love you even if you're not our client 😉",
  'High-Quality & Cost-Effective.',
];

// About us quotes
export const ABOUT_QUOTES = {
  Miguel:
    "I’m a self-taught web developer with a background as a protective services officer. This profession taught me how to effectively listen to details and successfully discern what’s not being said. Enabling me to better understand my clients' needs, which allows me to provide and implement beneficial solutions for their project.",
  Jonah:
    "For the past 3 years, I've had the privilege to do exactly what I love. That is, creating remarkable end-products that serve a purpose and help make a difference. Through each project came a challenge and through each challenge came more experience. It is through this experience that I finally came upon my passion to create pixel-perfect applications for the web. I look forward to many more projects and to keep expanding my skills in the world of a developer!"
};
