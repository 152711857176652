import { useEffect, useState } from 'react';
import { PACKAGES } from '../Constants'
import AOS from 'aos';
import Container from '../components/Container';
import Button from '../components/Button';

const Contact = ({ handleNavBgColor, packageRequest, setPackageRequest }) => {
  const INPUT_FIELD_CLASSNAME =
    'block w-full rounded-sm py-3 px-4 border border-gray-300 shadow-sm focus:border-primary-600 focus:ring-primary-600 text-base text-gray-700';
  const INPUT_LABEL_CLASSNAME = 
    'block text-base font-bold text-gray-700';
  const AREA_CODE_CLASSNAME =
    'h-full rounded-sm border-transparent bg-transparent py-0 pl-4 pr-8 text-gray-500 focus:border-primary-600 focus:ring-primary-600';
  const PACKAGE_RADIO_BTN_CLASSNAME =
    'h-4 w-4 shadow-sm border border-gray-300 text-primary-600 focus:ring-primary-500';
  const PACKAGE_RADIO_TEXT_CLASSNAME = 
    'block text-base text-gray-700';

  useEffect(() => {
    handleNavBgColor('default');
    AOS.refresh(); 
  });

  return (
    <>
      <Container>
        {/* Text above contact form */}
        <div className='pt-12 text-center'>
          <h2 className='text-5xl text-primary-600 font-extrabold antialiased capitalize'>
            Let's get it done!
          </h2>
          <p className='mt-4 max-w-3xl mx-auto text-xl text-gray-900'>
            We’re excited to work with you! Send us a message with your project descrption. We'll get back to you within 2 business days.
          </p>
        </div>

        {/* Contact Form */}
        <div className='pb-28 flex justify-center'>
          <form
            action='https://public.herotofu.com/v1/6757df90-305c-11ed-8e75-59d5c7f4376d'
            method='post'
            className='grid grid-cols-2 justify-center mt-12 sm:gap-x-12 w-full sm:w-9/12'
          >
            {/* FIRST NAME */}
            <div className='col-span-2 sm:col-span-1'>
              <label htmlFor='first-name' className={INPUT_LABEL_CLASSNAME}>
                First Name
              </label>
              <div>
                <input
                  type='text'
                  name='First name'
                  id='first-name'
                  autoComplete='given-name'
                  className={INPUT_FIELD_CLASSNAME}
                  required
                />
              </div>
            </div>
            {/* LAST NAME */}
            <div className='col-span-2 mt-4 sm:mt-0 sm:col-span-1'>
              <label htmlFor='first-name' className={INPUT_LABEL_CLASSNAME}>
                Last Name
              </label>
              <div>
                <input
                  type='text'
                  name='Last name'
                  id='last-name'
                  autoComplete='family-name'
                  className={INPUT_FIELD_CLASSNAME}
                  required
                />
              </div>
            </div>
            {/* COMPANY */}
            <div className='col-span-2 mt-4'>
              <label htmlFor='company-name' className={INPUT_LABEL_CLASSNAME}>
                Company
              </label>
              <div>
                <input
                  type='text'
                  name='Company name'
                  id='company-name'
                  autoComplete='organization'
                  className={INPUT_FIELD_CLASSNAME}
                  required
                />
              </div>
            </div>
            {/* EMAIL */}
            <div className='col-span-2 mt-4'>
              <label htmlFor='email' className={INPUT_LABEL_CLASSNAME}>
                Email
              </label>
              <div>
                <input
                  type='text'
                  name='Email'
                  id='email'
                  autoComplete='email'
                  className={INPUT_FIELD_CLASSNAME}
                  required
                />
              </div>
            </div>
            {/* PHONE NUMBER */}
            <div className='col-span-2 mt-4'>
              <label htmlFor='phone-number' className='flex justify-between'>
                <span className={INPUT_LABEL_CLASSNAME}>Phone Number</span>
                <span className='block text-sm text-gray-600'>optional</span>
              </label>
              <div className='relative mt-1 rounded-md shadow-sm'>
                <div className='absolute inset-y-0 left-0 flex items-center'>
                  <label htmlFor='country' className='sr-only'>
                    Country
                  </label>
                  <select
                    id='country'
                    name='country'
                    className={AREA_CODE_CLASSNAME}
                  >
                    <option>CA</option>
                    <option>US</option>
                    <option>EU</option>
                  </select>
                </div>
                <input
                  type='text'
                  name='phone-number'
                  id='phone-number'
                  autoComplete='tel'
                  className={INPUT_FIELD_CLASSNAME + ' pl-28'}
                  placeholder='+1 (555) 987-6543'
                  required
                />
              </div>
            </div>
            {/* MESSAGE */}
            <div className='col-span-2 mt-4'>
              <div className='flex justify-between'>
                <label
                  htmlFor='message-box'
                  className={INPUT_LABEL_CLASSNAME}
                >
                  Project Description
                </label>
                <span
                  id='how-can-we-help-description'
                  className='text-sm text-gray-500'
                >
                  max. 500 characters
                </span>
              </div>
              <div className='mt-1'>
                <textarea
                  id='message-box'
                  name='Message'
                  aria-describedby='how-can-we-help-description'
                  rows={4}
                  className={INPUT_FIELD_CLASSNAME}
                  defaultValue={''}
                  required
                />
              </div>
            </div>
            {/* PACKAGE OPTIONS */}
            <fieldset className='col-span-2 mt-4'>
              <legend className={INPUT_LABEL_CLASSNAME}>Package option</legend>
              <div>
                <div className='flex items-center mt-2'>
                  <input
                    id='package-1'
                    name='package'
                    defaultValue={PACKAGES.Package_one}
                    type='radio'
                    className={PACKAGE_RADIO_BTN_CLASSNAME}
                    checked={packageRequest === PACKAGES.Package_one}
                    onChange={() => setPackageRequest(PACKAGES.Package_one)}
                  />
                  <label htmlFor='package-1' className='ml-3'>
                    <span className={PACKAGE_RADIO_TEXT_CLASSNAME}>
                      {PACKAGES.Package_one}
                    </span>
                  </label>
                </div>

                <div className='flex items-center mt-2'>
                  <input
                    id='package-2'
                    name='package'
                    defaultValue={PACKAGES.Package_two}
                    type='radio'
                    className={PACKAGE_RADIO_BTN_CLASSNAME}
                    checked={packageRequest === PACKAGES.Package_two}
                    onChange={() => setPackageRequest(PACKAGES.Package_two)}
                  />
                  <label htmlFor='package-2' className='ml-3'>
                    <span className={PACKAGE_RADIO_TEXT_CLASSNAME}>
                      {PACKAGES.Package_two}
                    </span>
                  </label>
                </div>

                <div className='flex items-center mt-2'>
                  <input
                    id='package-3'
                    name='package'
                    defaultValue={PACKAGES.Package_three}
                    type='radio'
                    className={PACKAGE_RADIO_BTN_CLASSNAME}
                    checked={packageRequest === PACKAGES.Package_three}
                    onChange={() => setPackageRequest(PACKAGES.Package_three)}
                  />
                  <label htmlFor='package-3' className='ml-3'>
                    <span className={PACKAGE_RADIO_TEXT_CLASSNAME}>
                      {PACKAGES.Package_three}
                    </span>
                  </label>
                </div>

                <div className='flex items-center mt-2'>
                  <input
                    id='package-custom'
                    name='package'
                    defaultValue={PACKAGES.Package_custom}
                    type='radio'
                    className={PACKAGE_RADIO_BTN_CLASSNAME}
                    checked={packageRequest === PACKAGES.Package_custom}
                    onChange={() => setPackageRequest(PACKAGES.Package_custom)}
                  />
                  <label htmlFor='package-custom' className='ml-3'>
                    <span className={PACKAGE_RADIO_TEXT_CLASSNAME}>
                      {PACKAGES.Package_custom}
                    </span>
                  </label>
                </div>
              </div>
            </fieldset>

            <div className='mt-8'>
              <Button />
            </div>
          </form>
        </div>
      </Container>
    </>
  );
};

export default Contact;
