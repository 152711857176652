import { useEffect } from "react"
import HeroSection from '../sections/home/HeroSection'
import FeatureSection from '../sections/home/FeatureSection';
import ServiceSection from '../sections/home/ServiceSection';
import CloudLogoSection from '../sections/home/CloudLogoSection';
import ProcessSection from '../sections/home/ProcessSection';
import CTASection from '../sections/home/CTASection';
import TemplateSection from '../sections/home/TemplateSection';

const Home = ({social, handleNavBgColor, onPageChange}) => {
  
  useEffect(() => {
    handleNavBgColor('default')
  })

  return (
    <>
      <HeroSection social={social} onPageChange={onPageChange} />
      <FeatureSection />
      <ServiceSection />
      <CloudLogoSection />
      <ProcessSection />
      <CTASection onPageChange={onPageChange} />
      {/* <TemplateSection /> */}
    </>
  );
};

export default Home;
